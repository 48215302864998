<template>
  <div class="user-info">
    <a-popover trigger="hover" placement="bottom" overlayClassName="user-info-dropdown">
      <div class="title">
        <div class="submenu-title-wrapper" :class="{'two-line': auth.branches.length > 1}">
          <div class="name"><b>{{ auth.user.userName }}</b></div>
          <div class="name">{{ activeBranchName }}</div>
        </div>
        <a-badge :count="countMessage" title="Количество уведомлений">
          <a-avatar :src="getApiUrl + auth.user.userAvatar">
            <template #icon>
              <user-outlined/>
            </template>
          </a-avatar>
        </a-badge>
      </div>

      <template #content>
        <div>
          <a-menu v-if="auth.user" v-model:selectedKeys="current">
            <a-menu-item key="Profile">
              <router-link :to="{ name: 'Profile' }">Профиль</router-link>
            </a-menu-item>
            <a-menu-item key="Notifications">
              <router-link :to="{ name: 'Notifications' }">
                <a-badge class="relative-badge" :count="countMessage" title="Количество уведомлений">
                  Уведомления
                </a-badge>
              </router-link>
            </a-menu-item>
            <a-menu-item key="Logout">
              <a @click="handleLogout()">Выход</a>
            </a-menu-item>
          </a-menu>

          <div class="branch" v-if="auth.branches.length > 1">
            <h2>Филиал:</h2>
            <div
                class="branch-item" :class="{active: branch.idBranch == auth.currentBranch}"
                v-for="branch in auth.branches" :key="branch.idBranch"
                @click="() => handleChangeBranch(branch.idBranch)"
            >
              {{ branch.branchName }}
            </div>
          </div>
        </div>
      </template>
    </a-popover>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getApiUrl } from "../../utils/getUrls";
import { notification, Button } from 'ant-design-vue';
import api from "@/api/axios";
import webSocket from "@/mixins/webSocket";

export default {
  name: "UserInfo",
  mixins: [ webSocket ],
  data() {
    return {
      current: [ "" ],
      countMessage: 0,
      getApiUrl
    };
  },
  watch: {
    $route() {
      this.setCurrent()
    },
    socketData() {
      if (
          this.socketData.type == 'notifications' &&
          this.socketData.data &&
          this.socketData.data.statusNotification == 0
      ) {
        this.countMessage++;
        notification.open({
          placement: 'bottomRight',
          duration: 0,
          description: `Новое уведомление: ${this.socketData.data.textNotification}`,
        });
      }
    }
  },
  created() {
    this.setCurrent();
    this.initNotifications();
  },
  computed: {
    ...mapGetters({ auth: "auth", socketData: 'WSData' }),
    activeBranchName() {
      if (this.auth && this.auth.branches) {
        const branch = this.auth.branches.find(item => item.idBranch == this.auth.currentBranch);

        return branch.branchName;
      }

      return '';
    }
  },
  methods: {
    ...mapActions([ "logout", 'startLoading', 'stopLoading' ]),

    handleLogout() {
      this.startLoading();
      this.logout()
    },

    setCurrent() {
      const name = this.$route.name;

      if (name) {
        this.current = [ name ];
      }
    },

    handleChangeBranch(id) {
      this.startLoading()
      api.get(`/manage/auth/set-branch?id=${ id }`)
          .then(response => {
            const data = response.data;
            if (data.error) {
              this.$message.error(data.error);
              this.stopLoading()
            } else {
              location.reload();
            }
          });
    },

    initNotifications() {
      this.addWSSubscription({ type: 'notifications' })
      this.getNotifications();
    },

    getNotifications() {
      api.get(`/manage/notifications/get-unread`)
          .then(response => {
            const data = response.data;

            this.countMessage = 0;
            if (data.unread.length > 0) {
              this.countMessage = data.unread.length;
            }

            if (data.unShowed.length > 0) {
              data.unShowed.forEach(item => {
                notification.open({
                  placement: 'bottomRight',
                  duration: 0,
                  description: `Новое уведомление: ${item.textNotification}`,
                });
              });
            }
          });
    }
  }
};
</script>

<style lang="less">
.ant-notification-notice {
  background: #595959 !important;
  border-radius: 10px !important;

  .ant-notification-close-x, .ant-notification-notice-description {
    color: white;
  }

  .ant-notification-close-x:hover {
    color: #1890ff;
  }

  .ant-notification-notice-message {
    margin: 0;
  }

  .ant-notification-notice-content {
    padding-right: 20px;
  }
}

.user-info {
  margin-right: 20px;
  display: flex;

  .ant-badge-count {
    min-width: 20px;
    top: 4px !important;
  }

  .ant-avatar {
    margin-left: 5px;
  }

  a {
    color: rgba(0, 0, 0, 0.85);

    &:hover {
      color: #1890ff;
      border-bottom: 2px solid #1890ff
    }
  }

  .title {
    display: flex;
    justify-content: end;
    align-items: center;
  }

  .two-line {
    .name {
      text-align: right;
      line-height: 20px;
      padding-right: 10px;
    }
  }
}

.user-info-dropdown {
  position: fixed;

  .ant-popover-inner-content {
    padding: 0;

    .ant-menu {
      border-bottom: 1px solid #e8e8e8;
      border-right: none;
      border-radius: 0;

      .ant-menu-item {
        width: 100%;
        margin: 0;
        margin-bottom: 4px;
        height: 36px;
        line-height: 36px;

        &:hover {
          span:not(.ant-scroll-number-only) {
            color: #1890ff;
          }
        }

        .ant-badge {
          display: flex;
          height: 100%;
          align-items: center;
        }
        .ant-badge-count {
          display: inline-block;
          transform: none;
          width: 20px !important;
          height: 20px !important;
        }
      }
    }

    .branch {
      padding: 10px 16px 10px 16px;

      h2 {
        font-size: 18px;
      }

      .branch-item {
        width: calc(100% + 10px);
        position: relative;
        left: -5px;
        padding: 5px;
        white-space: nowrap;
        margin-top: 5px;

        &:hover, &.active {
          cursor: pointer;
          color: #1890ff;
          padding: 4px;
          border: 1px solid #1890ff;
          border-radius: 5px;
        }
      }
    }
  }
}

.relative-badge {
  .ant-badge-count {
    min-width: 20px;
    position: relative !important;
    margin-left: 5px !important;
    top: 0 !important;
  }
}
</style>