import authToken from "../../utils/authToken";
import websocket from "../../utils/getWebsocket";
import generateId from "@/utils/generateId";
import api from "@/api/axios";

let timer = null;
const connectionId = generateId();

const messageSettings = {
	currentReportMonth: {
		set: 'setReportMonthCurrent'
	},
	prevReportMonth: {
		set: 'setReportMonthPrev'
	},
	reportMonth: {
		set: 'saveReportMonth',
		isArray: true
	},
	report: {
		set: 'saveReport',
		remove: 'deleteReport',
		id: 'idReport'
	},
	schedules: {
		set: 'saveSchedule',
		remove: 'deleteSchedule',
		id: 'idSchedule'
	},
	delivery: {
		set: 'saveDelivery',
		remove: 'deleteDelivery',
		id: 'idDelivery'
	},
	timetable: {
		set: 'setTimetable',
		remove: 'deleteTimetable',
		id: 'idTimetable'
	},
	sales: {
		set: 'saveSales',
		remove: 'deleteSale',
		id: 'idSale'
	},
	services: {
		set: 'saveServices',
		remove: 'deleteService',
		id: 'idService'
	},
	preferences: {
		set: 'savePreferences',
		remove: 'deletePreference',
		id: 'idPreference'
	},
	categories: {
		set: 'setCategory'
	},
	auth: {
		set: 'setAuth'
	}
}

export default {
	state: {
		connection: null,
		updateData: true,
		data: {}
	},
	getters: {
		WSConnection: state => state.connection,
		WSData: state => state.data
	},
	actions: {
		WSConnect({ commit, state }) {
			if (!state.connection && authToken) {
				commit("WSConnect");
			}
		},
		
		WSConnectionOpen({ commit }, data = {}) {
			commit("WSConnectionOpen", data);
		},
		
		WSConnectionClose({ commit }) {
			commit("WSConnectionClose");
		},
	},
	mutations: {
		WSStopUpdate(state) {
			state.updateData = true;
		},
		
		WSStartUpdate(state) {
			state.updateData = false;
		},
		
		WSConnect(state, data = {}) {
			state.connection = new WebSocket(websocket);
		},
		
		WSConnectionOpen(state, data = {}) {
			state.connection.onopen = (event) => {
				state.connection.send(JSON.stringify({ action: 'getWebSocket', connectionId, id: authToken, ...data }));
				
				if (timer != null) {
					clearInterval(timer);
				}
				
				timer = setInterval(() => {
					state.connection.send(JSON.stringify({ action: 'check', connectionId }));
				}, 25000)
				
				if (data.func) {
					data.funct();
				}
			}
			
			state.connection.onmessage = async (e) => {
				if (state.updateData) {
					let data = JSON.parse(e.data);
					
					if (data.data && data.type) {
						state.data = {};
						
						if (!!messageSettings[data.type]) {
							const setting = messageSettings[data.type];
							
							if (data.data.action === 'remove') {
								if (setting.remove) {
									this.commit(setting.remove, { [setting.id]: data.data[setting.id] })
								}
							} else {
								if (setting.set) {
									this.commit(setting.set, setting.isArray ? [ data.data ] : data.data)
								}
							}
						} else {
							state.data = data;
						}
					}
				}
			}
		},
		WSConnectionClose(state) {
			if (state.connection) {
				state.connection.close();
				state.connection = null;
			}
		}
	}
};
