<template>
  <main>
    <div class="all-loading" v-if="someLoading || closingStatus">
      <div class="message">
        <a-progress
            v-if="closingStatus"
            type="circle"
            :percent="closingPercent"
            :status="closingStatus == 'error' ? 'exception' : ''"
        />
        <template v-else>
          <loading-outlined v-if="loadingStatus === ''" style="font-size: 120px" spin/>
          <a-progress v-else-if="loadingStatus === 'success'" type="circle" :percent="100"/>
          <a-progress v-else-if="loadingStatus === 'error'" type="circle" :percent="100" status="exception"/>
          <div class="mTop20 text" v-if="loadingMessage !== ''">
            {{ loadingMessage }}
          </div>
          <div v-if="loadingNeedClose">
            <a-button @click="stopLoading()" class="mTop20" type="primary">Закрыть</a-button>
          </div>
        </template>
      </div>
    </div>

    <template v-if="checkRecord">
      <router-view/>
    </template>
    <template v-else>
      <div class="all-loading" v-if="authLoading"></div>
      <template v-else-if="auth.isAdmin || auth.isSuperUser || auth.isMaster">
        <div class="top-block">
          <div class="pc-header displayFlexSBRN">
            <div class="displayFlex">
              <router-link class="logo" :to="{ name: 'HomeView' }">
                <logo/>
              </router-link>
              <Menu/>
            </div>
            <div class="displayFlex">
              <change-loader/>
              <UserInfo/>
            </div>
          </div>
          <div class="mobile-header">
            <div class="displayFlex">
              <router-link class="logo" :to="{ name: 'HomeView' }">
                <logo/>
              </router-link>
              <Menu/>
            </div>
            <div class="displayFlex">
              <change-loader/>
              <UserInfo/>
            </div>
          </div>
        </div>
        <div class="page-block">
          <router-view/>
        </div>
      </template>
      <user-auth v-else/>
    </template>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UserAuth from "@/components/user/Auth";
import Logo from "@/components/logo";
import Menu from "@/components/menu";
import UserInfo from "@/components/user/Info";
import ChangeLoader from "@/components/ChangeLoader";

export default {
  name: 'App',
  components: { ChangeLoader, UserInfo, Menu, Logo, UserAuth },
  data() {
    return {
      location: document.location
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth",
      closingStatus: "closingStatus",
      closingPercent: "closingPercent",
      loading: "loading",
      loadingMessage: "loadingMessage",
      loadingStatus: "loadingStatus",
      loadingNeedClose: "loadingNeedClose"
    }),
    checkRecord() {
      return [ '/login', '/record' ].includes(location.pathname) || location.pathname.includes('/record/')
    },

    authLoading: function () {
      return this.auth === false;
    },
    someLoading: function () {
      return this.loading === true;
    }
  },
  watch: {
    auth(newData, oldData) {
      if (newData.status == true && (oldData.status == false || oldData == false)) {
        this.handleWSConnectionOpen();
      }

      if (oldData.status == true && newData.status == false) {
        this.WSConnectionClose();
        this.WSConnect();
        this.WSConnectionOpen()
      }
    }
  },
  created() {
    this.getAuth();
    this.WSConnect();
    this.handleWSConnectionOpen();

    // получаем раз в минуту актуальный статус
    setInterval(() => {
      this.getAuth();
    }, 60000)
  },
  beforeUnmount() {
    this.WSConnectionClose();
  },
  methods: {
    ...mapActions([ "getAuth", 'stopLoading', 'WSConnect', 'WSConnectionOpen', 'WSConnectionClose' ]),

    handleWSConnectionOpen() {
      if (this.auth.status == true) {
        this.WSConnectionOpen({
          groups: this.auth.groups,
          idUser: this.auth.user.idUser,
          idBranch: this.auth.currentBranch
        });
      }
    }
  }
}
</script>

<style>
#app {
  overflow: hidden;
}
</style>