<template>
  <a-menu v-model:selectedKeys="current" mode="horizontal">
    <template v-if="checkSettings('menu', 'summary') && (auth.isMaster || auth.isSuperUser)">
      <a-menu-item key="SummaryTimetable">
        <router-link :to="{ name: 'SummaryTimetable' }">Обзор</router-link>
      </a-menu-item>
    </template>
    <template v-if="!auth.isMaster || auth.isSuperUser">
      <a-menu-item key="CalendarTimetable">
        <router-link :to="{ name: 'CalendarTimetable' }">Записи</router-link>
      </a-menu-item>
      <a-menu-item key="Report" v-if="checkSettings('menu', 'report')">
        <router-link :to="{ name: 'Report' }">Отчет</router-link>
      </a-menu-item>
      <a-menu-item key="Warehouse" v-if="checkSettings('menu', 'warehouse')">
        <router-link :to="{ name: 'Warehouse' }">Склад</router-link>
      </a-menu-item>
      <a-sub-menu key="MonthReport" v-if="auth.isSuperUser">
        <template #title>
          <router-link class="submenu-title-wrapper" :to="{ name: 'MonthReportView' }">Аналитика</router-link>
        </template>

        <a-menu-item key="MonthReportView">
          <router-link :to="{ name: 'MonthReportView' }">Ежемесячный отчет</router-link>
        </a-menu-item>

        <a-menu-item key="MonthReportPnL">
          <router-link :to="{ name: 'MonthReportPnL' }">PnL</router-link>
        </a-menu-item>
      </a-sub-menu>
      <a-sub-menu v-if="auth.isSuperUser || auth.isAdmin">
        <template #title>
          <router-link class="submenu-title-wrapper" :to="{ name: 'TasksBoard' }">Задачи</router-link>
        </template>

        <a-menu-item v-if="0" key="TasksPlanning">
          <router-link :to="{ name: 'TasksPlanning' }">План</router-link>
        </a-menu-item>

        <a-menu-item key="TasksList">
          <router-link :to="{ name: 'TasksList' }">Список</router-link>
        </a-menu-item>

        <a-menu-item key="TasksBoard">
          <router-link :to="{ name: 'TasksBoard' }">Доска</router-link>
        </a-menu-item>
      </a-sub-menu>
    </template>
    <a-menu-item key="Docs">
      <router-link class="submenu-title-wrapper" :to="{ name: 'Docs' }">Документация</router-link>
    </a-menu-item>
    <a-sub-menu v-if="auth.isSuperUser">
      <template #title>
        <span class="submenu-title-wrapper">
          <database-outlined/>Данные
        </span>
      </template>
      <a-menu-item key="Clients">
        <router-link :to="{ name: 'Clients' }">Клиентская база</router-link>
      </a-menu-item>
      <a-menu-item key="CostPrices">
        <router-link :to="{ name: 'CostPrices' }">Услуги, себестоимость</router-link>
      </a-menu-item>
      <a-menu-item key="Preferences" v-if="checkSettings('menu', 'data-preferences')">
        <router-link :to="{ name: 'Preferences' }">Предпочтения</router-link>
      </a-menu-item>
      <a-menu-item key="Sales" v-if="checkSettings('menu', 'data-sales')">
        <router-link :to="{ name: 'Sales' }">Продажи</router-link>
      </a-menu-item>
      <a-menu-item key="Consumables" v-if="checkSettings('menu', 'data-consumables')">
        <router-link :to="{ name: 'Consumables' }">Расходники</router-link>
      </a-menu-item>
      <a-menu-item key="Users" v-if="checkSettings('menu', 'data-users')">
        <router-link :to="{ name: 'Users' }">{{
            checkSettings('users', 'resource', false) ? 'Ресурсы' : 'Сотрудники'
          }}
        </router-link>
      </a-menu-item>
      <a-menu-item key="Certificates">
        <router-link :to="{ name: 'Certificates' }">Сертификаты</router-link>
      </a-menu-item>
      <a-menu-item key="Settings">
        <router-link :to="{ name: 'Settings' }">
          <setting-outlined/>
          Настройки
        </router-link>
      </a-menu-item>
    </a-sub-menu>
  </a-menu>
</template>

<script>
import { mapGetters } from "vuex";
import settings from "@/mixins/settings";

export default {
  name: "MaMenu",
  mixins: [ settings ],
  data() {
    return {
      current: [ "" ]
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth"
    })
  },
  mounted() {
    this.setCurrent()
  },
  watch: {
    $route() {
      this.setCurrent()
    }
  },
  methods: {
    setCurrent() {
      const name = this.$route.name;

      if (name) {
        this.current = [ name ];
      }
    }
  }
};
</script>
